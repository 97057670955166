// Generative Art with Rectangular Prisms

let prisms = [];

function setup() {
  let canvas = createCanvas(windowWidth, windowHeight, WEBGL); // Change this line
  canvas.parent("background-canvas"); // Add this line
  canvas.style("position", "fixed"); // Add this line
  canvas.style("z-index", "-1"); // Add this line
  regenerateArt();
}

function draw() {
  background(0);
  // Removed orbitControl to disable interaction

  for (let i = 0; i < prisms.length; i++) {
    prisms[i].display();
    prisms[i].update();
  }
}

function regenerateArt() {
  prisms = [];
  randomSeed(floor(random(100000))); 

  const numPrisms = floor(random(30, 40));
  for (let i = 0; i < numPrisms; i++) {
    prisms.push(new Prism());
  }
}

class Prism {
  constructor() {
    this.x = random(-width / 2, width / 2);
    this.y = random(-height / 2, height / 2);
    this.z = random(-500, 500);
    this.width = random(20, 100);
    this.height = random(20, 100);
    this.depth = random(20, 100);
    this.speed = random(0.5, 2);
    this.direction = random() < 0.5 ? -1 : 1;
    this.color = color(random(255), random(255), random(255), 200);
  }

  display() {
    push();
    translate(this.x, this.y, this.z);
    fill(this.color);
    box(this.width, this.height, this.depth);
    pop();
  }

  update() {
    this.y += this.speed * this.direction;
    if (this.y > height / 2 || this.y < -height / 2) {
      this.direction = -this.direction;
    }
  }
}